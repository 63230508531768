/* Reset default styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Set base font and color */
  body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    background-color: #f4f4f4;
  }
  
  /* Set default styles for headings */
  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0.5em;
    font-weight: 600;
    line-height: 1.2;
  }
  
  h1 {
    font-size: 2.5em;
  }
  
  h2 {
    font-size: 2em;
  }
  
  h3 {
    font-size: 1.75em;
  }
  
  /* Set default styles for links */
  a {
    color: #0066cc;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  /* Set default styles for buttons */
  button {
    cursor: pointer;
    font-size: 1em;
    padding: 0.5em 1em;
    background-color: #0066cc;
    color: white;
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #0052a3;
  }
  
  button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  /* Set default styles for form elements */
  input, select, textarea {
    font-size: 1em;
    padding: 0.5em;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Set max-width for better readability on large screens */
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  /* Utility classes */
  .text-center {
    text-align: center;
  }
  
  .mt-1 {
    margin-top: 1em;
  }
  
  .mb-1 {
    margin-bottom: 1em;
  }